<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>直播管理</el-breadcrumb-item>
      <el-breadcrumb-item>举报列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="name" label="用户姓名"></el-table-column>
      <el-table-column prop="tel" label="电话"></el-table-column>
      <el-table-column prop="report_content" label="举报内容"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  mounted() {
    this.getlist(1);
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.getlist(val);
    },
    getlist(page) {
      this.axios
        .get("/livereport/get_gu_live_report?page=" + page + "&size=" + this.PageSize)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该举报, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/livereport/delete_gu_live_report", this.qs.stringify({ id: row.id }))
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
